import React, { useState } from "react"
import { Link } from "gatsby"
import { graphql } from "gatsby"
import styled from "styled-components"

import Layout from "../components/Layout"
import { Row } from "."
import SEO from "../components/SEO"
import {
  View,
  color,
  radius,
  Flex,
  SVG,
  ScrollView,
  mobile,
  desktop,
  ModalView,
} from "../components/Styles"
import { rgba } from "polished"
import { useTranslation } from "react-i18next"

const LexiconData = require("../contents/lexicon.json")

export default ({
  data: {
    allMarkdownRemark: { edges },
  },
}) => {
  const { t } = useTranslation()

  const PoolPosts = tag =>
    edges
      .filter(edge => edge.node.frontmatter.tags.includes(tag))
      .map(edge => (
        <View scale={1} mt={1.5} key={edge.node.id} paragraph>
          <Link to={edge.node.frontmatter.path}>
            {t(edge.node.frontmatter.title)}
          </Link>
        </View>
      ))

  return (
    <Layout>
      <SEO title={t("学院")} />
      <Row>
        <View scale={3} mt={1.5} mb={0.75} mr={1.5} weight={"bold"}>
          {t("猎币学院")}
        </View>

        <ScrollView>
          <Flex childFlex="none" aic gap={0.75}>
            <ScrollTo to={t("注册商")} id="registrar" />
            <ScrollTo to={t("猎币矿池")} id="pool" />
            <ScrollTo to={t("IOST 投票宝")} id="iostVoter" />
          </Flex>
        </ScrollView>
      </Row>

      <Row id="registrar" my={[2.5, 5]} pt={[2.5, 5]} bt={color.gray4}>
        <Flex gap={[2, null]} responsive>
          <View flex={3} scale={2} weight={"bold"}>
            {t("注册商")}
          </View>

          <Flex flex={7} column gap={4}>
            <View>
              <Class name={t("词汇")} />
              <View>
                <Flex gap={1} flexWrap>
                  {LexiconData.registrar.map(({ word, define }) => (
                    <Lexicon key={t(word)} word={t(word)} define={t(define)} />
                  ))}
                </Flex>
              </View>
            </View>

            <View>
              <Class name={t("文章")} />
              {PoolPosts("Registrar")}
            </View>
          </Flex>
        </Flex>
      </Row>

      <Row id="pool" my={[2.5, 5]} pt={[2.5, 5]} bt={color.gray4}>
        <Flex gap={[2, null]} responsive>
          <View flex={3} scale={2} weight={"bold"}>
            {t("猎币矿池")}
          </View>

          <Flex flex={7} column gap={4}>
            <View>
              <Class name={t("词汇")} />
              <View>
                <Flex gap={0.75} flexWrap>
                  {LexiconData.liebipool.map(({ word, define }) => (
                    <Lexicon key={t(word)} word={t(word)} define={t(define)} />
                  ))}
                </Flex>
              </View>
            </View>

            <View>
              <Class name={t("文章")} />
              {PoolPosts("猎币矿池")}
            </View>

            <View>
              <Class name={t("视频")} />
              <VideoGrid gap={1.5}>
                <VideoPreview
                  title="Sutler Ventures"
                  cover="https://cdn.liebi.com/resource/image/sutler_ventures-0003.png"
                  video="https://cdn.liebi.com/resource/video/sutler_ventures.mp4"
                  duration="0:59"
                />
                <VideoPreview
                  title="Bitboy Crypto"
                  cover="https://cdn.liebi.com/resource/image/bitboy_crypto-0004.png"
                  video="https://cdn.liebi.com/resource/video/bitboy_crypto.mp4"
                  duration="1:43"
                />
                <VideoPreview
                  title="Crypto Heem"
                  cover="https://cdn.liebi.com/resource/image/crypto_heem-0001.png"
                  video="https://cdn.liebi.com/resource/video/crypto_heem.mp4"
                  duration="1:31"
                />
                <VideoPreview
                  title={t("DappPulse 介绍矿池")}
                  cover="https://cdn.liebi.com/resource/image/dapppulse_1-0005.png"
                  video="https://cdn.liebi.com/resource/video/dapppulse_1.mp4"
                  duration="1:48"
                />
                <VideoPreview
                  title={t("DappPulse 介绍多签、VOST 用法")}
                  cover="https://cdn.liebi.com/resource/image/dapppulse_2-0001.png"
                  video="https://cdn.liebi.com/resource/video/dapppulse_2.mp4"
                  duration="4:45"
                />
                <VideoPreview
                  title={t("Tron NL（俄语）")}
                  cover="https://cdn.liebi.com/resource/image/tron_nl-0007.png"
                  video="https://cdn.liebi.com/resource/video/tron_nl.mp4"
                  duration="3:15"
                />
              </VideoGrid>
            </View>
          </Flex>
        </Flex>
      </Row>

      <Row id="iostVoter" my={[2.5, 5]} pt={[2.5, 5]} bt={color.gray4}>
        <Flex gap={[2, null]} responsive>
          <View flex={3} scale={2} weight={"bold"}>
            {t("IOST 投票宝")}
          </View>

          <Flex flex={7} column gap={4}>
            <View>
              <Class name={t("词汇")} />
              <View>
                <Flex gap={1} flexWrap>
                  {LexiconData.iostvoter.map(({ word, define }) => (
                    <Lexicon key={t(word)} word={t(word)} define={t(define)} />
                  ))}
                </Flex>
              </View>
            </View>

            <View>
              <Class name={t("文章")} />
              {PoolPosts("IOST 投票宝")}
            </View>
          </Flex>
        </Flex>
      </Row>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            path
            title
            tags
          }
        }
      }
    }
  }
`

const VideoGrid = styled.div`
  display: flex;
  flex-flow: row wrap;

  > * {
    flex-grow: 0;
  }

  ${mobile`
    > * {
      flex-basis: calc((100% - 1.5em) / 2);
      margin-bottom: ${p =>
        p.gap &&
        (!p.column &&
          (typeof p.gap === "number"
            ? p.gap + "em"
            : p.gap[0] && p.gap[0] + "em"))};
    }

    margin-bottom: ${p =>
      p.gap &&
      (!p.column &&
        (typeof p.gap === "number"
          ? "-" + p.gap + "em"
          : p.gap[0] && "-" + p.gap[0] + "em"))};

    > *:not(:nth-child(2n)){
      margin-right: ${p =>
        p.gap &&
        (!p.column &&
          (typeof p.gap === "number"
            ? p.gap + "em"
            : p.gap[0] && p.gap[0] + "em"))};
    }

  `};

  ${desktop`
    > * {
      flex-basis: calc((100% - 3em) / 3);
      margin-bottom: ${p =>
        p.gap &&
        (!p.column &&
          (typeof p.gap === "number"
            ? p.gap + "em"
            : p.gap[1] && p.gap[1] + "em"))};
    }

    margin-bottom: ${p =>
      p.gap &&
      (!p.column &&
        (typeof p.gap === "number"
          ? "-" + p.gap + "em"
          : p.gap[1] && "-" + p.gap[1] + "em"))};

    > *:not(:nth-child(3n)){
      margin-right: ${p =>
        p.gap &&
        (!p.column &&
          (typeof p.gap === "number"
            ? p.gap + "em"
            : p.gap[1] && p.gap[1] + "em"))};
    }
  `};
`

const ScrollTo = ({ to, id }) => (
  <Flex
    pointer
    aic
    p={0.75}
    color={color.liebi}
    b={color.liebi}
    r={radius.md}
    onClick={() =>
      window.scrollTo(0, document.getElementById(id).offsetTop + 2)
    }
  >
    <SVG svg={magnifier} fill={color.liebi} withText />
    <View weight={"bold"}>{to}</View>
  </Flex>
)

const magnifier = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M5.04785 9.69434C5.9502 9.69434 6.79395 9.43652 7.50879 8.99121L9.92285 11.4053C10.1162 11.5986 10.374 11.6924 10.6436 11.6924C11.2178 11.6924 11.6396 11.2471 11.6396 10.6846C11.6396 10.4209 11.5518 10.1689 11.3584 9.96973L8.96777 7.57324C9.4541 6.83496 9.74121 5.9502 9.74121 5.00098C9.74121 2.42285 7.62598 0.307617 5.04785 0.307617C2.46973 0.307617 0.360352 2.42285 0.360352 5.00098C0.360352 7.5791 2.46973 9.69434 5.04785 9.69434ZM5.04785 8.26465C3.25488 8.26465 1.78418 6.79395 1.78418 5.00098C1.78418 3.20801 3.25488 1.7373 5.04785 1.7373C6.84668 1.7373 8.31738 3.20801 8.31738 5.00098C8.31738 6.79395 6.84668 8.26465 5.04785 8.26465Z" />
  </svg>
)

const Class = ({ name }) => (
  <View>
    <View scale={2} mb={1} color={color.liebi}>
      {name}
    </View>
  </View>
)

const Lexicon = ({ word, define }) => {
  const [show, setShow] = useState(false)
  return (
    <View>
      <View
        weight={"bold"}
        p={0.75}
        pointer
        r={radius.md}
        color={color.white}
        bg={color.liebi}
        onClick={() => setShow(true)}
      >
        {word}
      </View>

      {show && (
        <ModalView p={[2.5, null]} close={() => setShow(false)}>
          <View
            position="relative"
            r={radius.lg}
            bg="linear-gradient(180deg,hsla(0,0%,100%,0) 60%,#fff),linear-gradient(70deg,hsl(171, 65%, 95%) 32%,hsl(243, 65%, 94%))"
          >
            <View scale={1} w={[null, 20]} p={1.25}>
              <View mb={0.75} weight={"bold"} color={color.liebi}>
                {word}
              </View>
              <View color={color.gray} paragraph>
                {define}
              </View>
            </View>

            <SVG
              z={2}
              scale={1}
              pointer
              position="absolute"
              p={0.75}
              top={0}
              right={0}
              svg={close}
              fill={color.gray}
              onClick={() => setShow(false)}
            />
          </View>
        </ModalView>
      )}
    </View>
  )
}

const VideoPreview = ({ title, video, cover, duration }) => {
  const [videoShow, setVideoShow] = useState(false)
  return (
    <View>
      <View
        weight={"bold"}
        position="relative"
        overflow="hidden"
        py={[3.15, 4.5]}
        pointer
        r={radius.lg}
        color={color.white}
        bgi={cover}
        bgs="cover"
        onClick={() => setVideoShow(true)}
      >
        <SVG
          z={1}
          svg={play}
          scale={1}
          p={0.5}
          fill={color.white}
          position="absolute"
          bottom={0}
          left={0}
        />
        <View
          z={1}
          scale={-1}
          p={0.5}
          r={radius.md}
          bg={rgba(color.black, 0.5)}
          position="absolute"
          bottom={0.75}
          right={0.75}
        >
          {duration}
        </View>
        <View
          position="absolute"
          top={0}
          right={0}
          bottom={0}
          left={0}
          bg={rgba(color.black, 0.3)}
        />
      </View>
      <View scale={-1} paragraph color={color.gray} p={0.5}>
        {title}
      </View>

      {videoShow && (
        <ModalView p={1.5} close={() => setVideoShow(false)}>
          <View
            z={1}
            position="relative"
            overflow="hidden"
            r={radius.xl}
            bg={color.black}
          >
            <SVG
              z={2}
              scale={2}
              pointer
              position="absolute"
              p={[0.75, 0.5]}
              top={0}
              right={0}
              svg={close}
              fill={color.black}
              onClick={() => setVideoShow(false)}
            />
            <video width="100%" poster={cover} autoPlay controls>
              <source src={video} type="video/mp4" />
              Your browser does not support HTML5 video.
            </video>
          </View>
        </ModalView>
      )}
    </View>
  )
}

const close = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M8 16C12.3686 16 16 12.3765 16 8C16 3.63137 12.3608 0 7.99216 0C3.61569 0 0 3.63137 0 8C0 12.3765 3.62353 16 8 16ZM5.18431 11.4824C4.81569 11.4824 4.51765 11.1843 4.51765 10.8157C4.51765 10.6353 4.59608 10.4784 4.72157 10.3608L7.05882 8.00784L4.72157 5.6549C4.59608 5.5451 4.51765 5.38039 4.51765 5.2C4.51765 4.83922 4.81569 4.54902 5.18431 4.54902C5.36471 4.54902 5.52157 4.61961 5.63922 4.7451L7.99216 7.0902L10.3608 4.73726C10.4941 4.59608 10.6353 4.53333 10.8078 4.53333C11.1765 4.53333 11.4745 4.83137 11.4745 5.19216C11.4745 5.37255 11.4118 5.52157 11.2784 5.64706L8.92549 8.00784L11.2706 10.3451C11.3882 10.4706 11.4667 10.6275 11.4667 10.8157C11.4667 11.1843 11.1686 11.4824 10.8 11.4824C10.6118 11.4824 10.4549 11.4039 10.3373 11.2863L7.99216 8.93333L5.6549 11.2863C5.53725 11.4118 5.36471 11.4824 5.18431 11.4824Z" />
  </svg>
)

const play = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M6 12C9.27647 12 12 9.28235 12 6C12 2.72353 9.27059 0 5.99412 0C2.71176 0 0 2.72353 0 6C0 9.28235 2.71765 12 6 12ZM6 11C3.22353 11 1.00588 8.77647 1.00588 6C1.00588 3.22941 3.21765 1 5.99412 1C8.76471 1 10.9941 3.22941 11 6C11.0059 8.77647 8.77059 11 6 11ZM4.96471 8.16471L8.11177 6.30588C8.34118 6.16471 8.33529 5.84118 8.11177 5.71177L4.96471 3.84118C4.72353 3.7 4.40588 3.81176 4.40588 4.07647V7.92941C4.40588 8.2 4.70588 8.31765 4.96471 8.16471Z" />
  </svg>
)
